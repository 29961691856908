import React, { useState } from 'react'
import styles from './formComponents.module.scss';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { getValue } from '@testing-library/user-event/dist/utils';

interface PersonalDetailsFormProps {
    mode: string,
    getPersonalData: any,
    toggleStoreForm: any,
    togglePaymentBtn: any,
    setDisabled: boolean
    
}

const PersonalDetailsForm: React.FC<PersonalDetailsFormProps> = ({ mode, getPersonalData, toggleStoreForm, togglePaymentBtn, setDisabled }) => {
    type personalDetailForm = {
        fName: string;
        lName: string;
        email: string;
        phone: number;
        radioOption:any,
        checkboxOptions:any,
    }

    const personalDetailsSchema = Yup.object().shape({
        fName: Yup.string().required('FirstName is required'),
        lName: Yup.string().required('LastName is required'),
        email: Yup.string()
            .required('Email is required')
            .email('Invalid email format'),
        phone: Yup.string()
        .required('Phone number is required')
        .matches(/^\d{3}-\d{3}-\d{4}$/, 'Invalid phone number format'),
    });


    /*personal Details form */
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control,
        
    } = useForm<personalDetailForm>({
        resolver: yupResolver(personalDetailsSchema),
        mode: 'onChange',
        defaultValues: {

        }
    })

    const onSubmit = (data: personalDetailForm) => {
        setformMode("read");
        getPersonalData(data);
        toggleStoreForm();
        togglePaymentBtn(true);
    }
    const [formMode, setformMode] = useState(mode);
    return (
        <>
            <div className={`${styles.personal_details} ${styles.form_accordion}`}>
                <div className={styles.form_accordion_head}>
                    <h3>
                        Personal Details
                    </h3>
                    <div>
                        {formMode === "read" &&
                            <a href="javascript:;" onClick={() => {setformMode("edit"); togglePaymentBtn(false)}}>+Edit</a>
                        }
                    </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} id="rePersonalDetails" className={`${formMode === "read" ? styles.form_readOnly : ''}`}>
                    <div className={styles.form_accordion_body}>
                        <div className={styles.row_group}>
                            <div className={styles.form_group}>
                                <label htmlFor="reFName">First Name <span className={styles.required}>*</span></label>
                                <input id="reFName"
                                    type="text"
                                    {...register('fName')}
                                    className={`form-control ${errors.fName ? 'is-invalid' : ''}`}
                                    readOnly={formMode === "read"}
                                    tabIndex={formMode === "read" ? -1 : 0}
                                    disabled={setDisabled}
                                />
                                <div className="invalid-feedback">{errors.fName?.message}</div>
                            </div>

                            <div className={styles.form_group}>
                                <label htmlFor='reLName'>Last Name <span className={styles.required}>*</span></label>
                                <input
                                    id="reLName"
                                    type="text"
                                    {...register('lName')}
                                    className={`form-control ${errors.lName ? 'is-invalid' : ''}`}
                                    readOnly={formMode === "read"}
                                    tabIndex={formMode === "read" ? -1 : 0}
                                    disabled={setDisabled}
                                />
                                <div className="invalid-feedback">{errors.lName?.message}</div>
                            </div>
                        </div>

                        <div className={styles.row_group}>
                            <div className={styles.form_group}>
                                <label htmlFor='reEmail'>Email <span className={styles.required}>*</span></label>
                                <input
                                    id="reEmail"
                                    type="text"
                                    {...register('email')}
                                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                    readOnly={formMode === "read"}
                                    tabIndex={formMode === "read" ? -1 : 0}
                                    disabled={setDisabled}
                                />
                                <div className="invalid-feedback">{errors.email?.message}</div>
                            </div>

                            <div className={styles.form_group}>
                                <label htmlFor='rePhone'>Phone <span className={styles.required}>*</span></label>
                                <InputMask
                                    mask="999-999-9999"
                                    maskChar=""
                                    type="tel"
                                    placeholder='999-999-9999'
                                    className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                                    {...register('phone')}
                                    readOnly={formMode === "read"}
                                    tabIndex={formMode === "read" ? -1 : 0}
                                    disabled={setDisabled}
                                    // onPaste={(event: { preventDefault: () => void; })=>{event.preventDefault();}}
                                />
                                <div className="invalid-feedback">{errors.phone?.message}</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {formMode === "edit" &&
                            <button 
                                className={`${styles.primaryButton} ${styles.next_btn} ${setDisabled ? styles.disabled: ''}`}
                                disabled={setDisabled}
                            >
                                Next
                            </button>
                        }
                    </div>
                </form>
            </div>
        </>
    )
}

export default PersonalDetailsForm;